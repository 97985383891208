export const HSRMocHisto = [
  {
    slug: 'acheron',
    score: 8.31,
    usage: 59.37,
    role: 'dps'
  },
  {
    slug: 'argenti',
    score: 9.13,
    usage: 2.53,
    role: 'dps'
  },
  {
    slug: 'arlan',
    score: 11,
    usage: 0.03,
    role: 'dps'
  },
  {
    slug: 'asta',
    score: 9.17,
    usage: 1.96,
    role: 'amplifier'
  },
  {
    slug: 'aventurine',
    score: 7.96,
    usage: 40.82,
    role: 'sustain'
  },
  {
    slug: 'bailu',
    score: 8.97,
    usage: 2.28,
    role: 'sustain'
  },
  {
    slug: 'black-swan',
    score: 8.72,
    usage: 24.71,
    role: 'specialist'
  },
  {
    slug: 'blade',
    score: 8.61,
    usage: 2.22,
    role: 'dps'
  },
  {
    slug: 'boothill',
    score: 8.25,
    usage: 12.16,
    role: 'specialist'
  },
  {
    slug: 'bronya',
    score: 8.28,
    usage: 17.96,
    role: 'amplifier'
  },
  {
    slug: 'clara',
    score: 8.64,
    usage: 12.81,
    role: 'dps'
  },
  {
    slug: 'dan-heng',
    score: 11,
    usage: 0.03,
    role: 'dps'
  },
  {
    slug: 'dr-ratio',
    score: 8.13,
    usage: 12.34,
    role: 'dps'
  },
  {
    slug: 'feixiao',
    score: 6.07,
    usage: 22.37,
    role: 'dps',
    new: true
  },
  {
    slug: 'firefly',
    score: 7.92,
    usage: 64.89,
    role: 'specialist'
  },
  {
    slug: 'fu-xuan',
    score: 8.25,
    usage: 43.16,
    role: 'sustain'
  },
  {
    slug: 'gallagher',
    score: 8.04,
    usage: 58.68,
    role: 'sustain'
  },
  {
    slug: 'gepard',
    score: 8.65,
    usage: 3.79,
    role: 'sustain'
  },
  {
    slug: 'guinaifen',
    score: 9.07,
    usage: 5.77,
    role: 'amplifier'
  },
  {
    slug: 'hanya',
    score: 9.03,
    usage: 0.89,
    role: 'amplifier'
  },
  {
    slug: 'herta',
    score: 10,
    usage: 0.37,
    role: 'dps'
  },
  {
    slug: 'himeko',
    score: 9.16,
    usage: 11.95,
    role: 'dps'
  },
  {
    slug: 'hook',
    score: 10.42,
    usage: 0.08,
    role: 'dps'
  },
  {
    slug: 'huohuo',
    score: 8.44,
    usage: 25.34,
    role: 'sustain'
  },
  {
    slug: 'imbibitor-lunae',
    score: 8.57,
    usage: 6.17,
    role: 'dps'
  },
  {
    slug: 'jade',
    score: 7.82,
    usage: 2.95,
    role: 'specialist'
  },
  {
    slug: 'jiaoqiu',
    score: 7.18,
    usage: 25.42,
    role: 'amplifier',
    new: true
  },
  {
    slug: 'jing-yuan',
    score: 8.99,
    usage: 2.92,
    role: 'dps'
  },
  {
    slug: 'jingliu',
    score: 8.69,
    usage: 8.13,
    role: 'dps'
  },
  {
    slug: 'kafka',
    score: 8.84,
    usage: 24.85,
    role: 'specialist'
  },
  {
    slug: 'luka',
    score: 9.63,
    usage: 0.8,
    role: 'specialist'
  },
  {
    slug: 'luocha',
    score: 8.6,
    usage: 17.99,
    role: 'sustain'
  },
  {
    slug: 'lynx',
    score: 8.62,
    usage: 2.07,
    role: 'sustain'
  },
  {
    slug: 'march-7th',
    score: 10.42,
    usage: 0.28,
    role: 'sustain'
  },
  {
    slug: 'march-7th-swordmaster',
    score: 6.84,
    usage: 16.84,
    role: 'specialist',
    new: true
  },
  {
    slug: 'misha',
    score: 11,
    usage: 0.09,
    role: 'dps'
  },
  {
    slug: 'moze',
    score: 5.48,
    usage: 2.48,
    role: 'specialist',
    new: true
  },
  {
    slug: 'natasha',
    score: 10.24,
    usage: 0.22,
    role: 'sustain'
  },
  {
    slug: 'pela',
    score: 8.26,
    usage: 39.52,
    role: 'amplifier'
  },
  {
    slug: 'qingque',
    score: 8.94,
    usage: 0.74,
    role: 'dps'
  },
  {
    slug: 'robin',
    score: 7.69,
    usage: 35.88,
    role: 'amplifier'
  },
  {
    slug: 'ruan-mei',
    score: 8.29,
    usage: 83.66,
    role: 'amplifier'
  },
  {
    slug: 'sampo',
    score: 9.74,
    usage: 0.46,
    role: 'specialist'
  },
  {
    slug: 'seele',
    score: 8.73,
    usage: 1.56,
    role: 'dps'
  },
  {
    slug: 'serval',
    score: 10.42,
    usage: 0.28,
    role: 'dps'
  },
  {
    slug: 'silver-wolf',
    score: 8.37,
    usage: 29.35,
    role: 'amplifier'
  },
  {
    slug: 'sparkle',
    score: 8.5,
    usage: 36.09,
    role: 'amplifier'
  },
  {
    slug: 'sushang',
    score: 8.96,
    usage: 0.59,
    role: 'specialist'
  },
  {
    slug: 'tingyun',
    score: 8.4,
    usage: 14.78,
    role: 'amplifier'
  },
  {
    slug: 'topaz',
    score: 7.73,
    usage: 19.82,
    role: 'specialist'
  },
  {
    slug: 'trailblazer-fire',
    score: 11,
    usage: 0.18,
    role: 'specialist'
  },
  {
    slug: 'trailblazer-imaginary',
    score: 8.16,
    usage: 59.37,
    role: 'amplifier'
  },
  {
    slug: 'trailblazer-physical',
    score: 11,
    usage: 0.0,
    role: 'dps'
  },
  {
    slug: 'welt',
    score: 9.34,
    usage: 1.31,
    role: 'specialist'
  },
  {
    slug: 'xueyi',
    score: 11,
    usage: 0.17,
    role: 'specialist'
  },
  {
    slug: 'yanqing',
    score: 10.24,
    usage: 0.2,
    role: 'dps'
  },
  {
    slug: 'yukong',
    score: 11,
    usage: 0.13,
    role: 'amplifier'
  },
  {
    slug: 'yunli',
    score: 7.28,
    usage: 16.18,
    role: 'dps',
    new: true
  }
];
